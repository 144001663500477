import React, { useEffect, useState } from "react";
import logo from "../../assets/images/bway-headshots-white-logo.svg";
import wave1 from "../../assets/images/wave1.svg";
import wave2 from "../../assets/images/wave2.svg";
import eyeOff from "../../assets/images/eye-off.svg";
import eyeOn from "../../assets/images/eye-on.svg";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../common/loadingScreen";
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const Login = ({ state, setState }) => {
  const navigate = useNavigate();
  const { cookie, email } = state;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    setLoginErrorMessage("");
    setShowLoader(true);
    e.preventDefault();

    axios
      .post(`${backendBaseUrl}/bo/login`, {
        email: email.toLowerCase(),
        password: password,
      })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          cookie: response.data,
        }));
        Cookies.set("token", response.data, { expires: 1 });
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        if (error.response) setLoginErrorMessage(error.response.data);
        else
          setLoginErrorMessage(
            "Cannot connect to server. Try again after some time.",
          );
      })
      .finally(() => setShowLoader(false));
  };

  useEffect(() => {
    if (cookie) {
      navigate("/", { replace: true });
    }
  }, [navigate, cookie]);

  return (
    <>
      {showLoader && <LoadingScreen />}
      <div className="bg-beige-600 h-screen flex flex-col justify-center items-center">
        <img
          src={wave2}
          alt=""
          className="absolute top-0 opacity-10 -z-0 pointer-events-none"
        />
        <img
          src={wave1}
          alt=""
          className="absolute bottom-0 opacity-15 -z-0 pointer-events-none"
        />
        <img
          src={logo}
          alt=""
          className="absolute top-0 h-24 pointer-events-none"
        />
        <div className="bg-white z-10 px-10 pb-14 pt-8 rounded-2xl shadow-md shadow-amber-700 border-[1px] border-amber-900 w-10/12 md:w-1/2 lg:w-1/3">
          <h1 className="text-3xl font-sans uppercase font-bold mb-8 text-center">
            Admin
          </h1>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                className="block font-semibold text-gray-700 mb-2"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                name="email"
                type="email"
                value={email}
                required
                autoComplete="bway-email"
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                  setLoginErrorMessage("");
                }}
                placeholder="Enter your email address"
              />
            </div>
            <div className="mb-4">
              <label
                className="block font-semibold text-gray-700 mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  className="border rounded w-full py-2 pl-3 pr-10 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  required
                  autoComplete="bway-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setLoginErrorMessage("");
                  }}
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  className="absolute w-6 inset-y-0 right-2 pb-2 focus:outline-none"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={eyeOn} alt="eyeOn" />
                  ) : (
                    <img src={eyeOff} alt="eyeOff" />
                  )}
                </button>
              </div>
            </div>
            <div className="mb-6 flex justify-center">
              <button
                className="bg-blue-500 focus:outline-blue-700 hover:bg-blue-700 w-1/3 text-white font-bold py-3 uppercase rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Login
              </button>
            </div>
            <div className="text-center text-red-600 bgre">
              {loginErrorMessage}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
