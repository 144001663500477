import React, { useEffect, useState } from "react";
import CancelIcon from "../../assets/images/close-icon.svg";
import { ORDER_STATUS_VALUES } from "../../services/constants";
import axios from "axios";
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

let pdfjs;
(async function () {
  pdfjs = await import("pdfjs-dist/build/pdf");
  const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker");
  pdfjs.GlobalWorkerOptions.workerSrc = "" + pdfjsWorker;
})();

const OrderStatusModel = ({
  orderInformation,
  setOrderInformation,
  setShowOrderStatus,
  handleOrderStatusChange,
  setShowToast,
  setToastMessage,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfImageBase64, setPdfImageBase64] = useState(null);

  const animationStyles = {
    transform: isVisible ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.4s ease-in-out",
  };

  const getProductName = (orderInfo) => {
    switch (orderInfo.productCode) {
      case "prints":
        return "Headshot Prints";
      case "digital":
        return "Digital Headshot";
      case "design":
        return "Your Design";
      case "resume":
        switch (orderInfo.productSubCode) {
          case "color":
            return "Resume 8x10 (Color)";
          case "bw":
            return "Resume 8x10 (B&W)";
          default:
            return "Resume 8x10 (?)";
        }
      default:
        return "(Unknown Product)";
    }
  };

  const handleCloseButton = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShowOrderStatus(false);
    }, 400);
  };

  const downloadSlip = () => {
    axios
      .get(`${backendBaseUrl}/order/packing-slip`, {
        params: { id: orderInformation._id },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${response.data}`;
        link.download = `${orderInformation.orderNumber} - Packing Slip.pdf`;
        link.click();
      })
      .catch((e) => {
        console.log("error ", e);
        setToastMessage(
          `Error downloading packing slip. Please try again later.`,
        );
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      });
  };

  /**
   * Get date & time from UTC date-time
   */
  const getDateTimeFromUTC = (utcDateString) => {
    const utcDate = new Date(utcDateString);

    // Get the date part in the format 'Thu Dec 05 2024'
    const date = utcDate.toDateString();

    // Get the time part in the format '3:55 PM'
    const time = utcDate.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    }).toLowerCase();

    return `${date} - ${time}`;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
    axios
      .get(`${backendBaseUrl}/pdf`, {
        params: { id: orderInformation.pdfID },
      })
      .then((response) => {
        setPdfBase64(response.data);
        const canvas = document.createElement("canvas");
        pdfjs.getDocument({ data: atob(response.data) }).promise.then((pdf) => {
          pdf.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1 });
            const canvasContext = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
              canvasContext,
              viewport,
            };
            page.render(renderContext).promise.then(() => {
              const imgData = canvas.toDataURL("image/jpeg");
              setPdfImageBase64(imgData);
            });
          });
        });
        canvas.remove();
      })
      .catch((e) => console.log("error ", e));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="fixed left-0 right-0 top-0 z-30 h-full w-full overflow-hidden bg-black-400/[.4]"
        style={animationStyles}
      >
        <div className="fixed bg-gray-50 w-1/2 left-0 right-0 max-h-[80%] top-[50%] -translate-y-2/4 px-3 sm:px-0 mx-auto shadow-xl shadow-slate-600 max-w-full rounded-2xl overflow-auto">
          <div className="flex items-center justify-between bg-beige-600 pl-8 py-2 pr-2">
            <h2 className="text-lg text-white font-semibold">
              {`Order Information: ${orderInformation.orderNumber}`}
            </h2>

            <button
              type="button"
              className="close duration-200 hover:bg-yellow-700 rounded-lg p-2"
              onClick={() => handleCloseButton()}
            >
              <img
                alt="close"
                src={CancelIcon}
                className="h-[18px] w-[18px] pointer-events-none"
              />
            </button>
          </div>
          <div className="p-4 max-h-[70vh] flex overflow-hidden">
            <div className="shadow-inner overflow-auto shadow-[#a9a9a9] pr-1 py-1 bg-white rounded-xl w-2/3 border-r">
              <div className="px-6 py-3 flex flex-col space-y-2">
                <div>
                  <b>Product:</b> {getProductName(orderInformation)}
                </div>

                <div>
                  <b>Rush Order:</b>{" "}
                  {orderInformation.expeditedShipping ? "Yes" : "No"}
                </div>
                <div>
                  <b>Payment:</b> {orderInformation.payment}
                </div>
                {orderInformation.productCode !== "resume" && (
                  <div>
                    <b>Promotion Use:</b> {orderInformation.promotion}
                  </div>
                )}
                {orderInformation.productType !== "Digital" &&
                  orderInformation.productCode !== "resume" && (
                    <div>
                      <b>Paper Type:</b> {orderInformation.paperType}
                    </div>
                  )}
                {orderInformation.productType !== "Digital" && (
                  <div>
                    <b>Shipping Date:</b> {orderInformation.shippingDate}
                  </div>
                )}
                <div>
                  <b>Quantity: </b>
                  {orderInformation.productType === "Digital" && (
                    <span className="text-slate-500 italic">
                      NA - Digital Order
                    </span>
                  )}
                  {orderInformation.productType !== "Digital" && (
                    <span>{orderInformation.quantity}</span>
                  )}
                </div>
                {orderInformation.billingAddress && (
                  <div>
                    <b>Billing Address:</b>
                    <ul className="pl-6">
                      <li>
                        <b>Street 1:</b>{" "}
                        {orderInformation.billingAddress.street1}
                      </li>
                      <li>
                        <b>Street 2:</b>{" "}
                        {orderInformation.billingAddress.street2}
                      </li>
                      <li>
                        <b>City:</b> {orderInformation.billingAddress.city}
                      </li>
                      <li>
                        <b>State:</b> {orderInformation.billingAddress.state}
                      </li>
                      <li>
                        <b>Country:</b>{" "}
                        {orderInformation.billingAddress.country}
                      </li>
                      <li>
                        <b>Zipcode:</b>{" "}
                        {orderInformation.billingAddress.zipcode}
                      </li>
                    </ul>
                  </div>
                )}
                {orderInformation.shippingAddress && (
                  <div>
                    <b>Shipping Address:</b>
                    <ul className="pl-6">
                      <li>
                        <b>Street 1:</b>{" "}
                        {orderInformation.shippingAddress.street1}
                      </li>
                      <li>
                        <b>Street 2:</b>{" "}
                        {orderInformation.shippingAddress.street2}
                      </li>
                      <li>
                        <b>City:</b> {orderInformation.shippingAddress.city}
                      </li>
                      <li>
                        <b>State:</b> {orderInformation.shippingAddress.state}
                      </li>
                      <li>
                        <b>Country:</b>{" "}
                        {orderInformation.shippingAddress.country}
                      </li>
                      <li>
                        <b>Zipcode:</b>{" "}
                        {orderInformation.shippingAddress.zipcode}
                      </li>
                    </ul>
                  </div>
                )}
                <div>
                  <b>Pickup:</b> {orderInformation.pickup ? "Yes" : "No"}
                </div>
                <div>
                  <b>User Email:</b> {orderInformation.email}
                </div>
                <div>
                  <b>User Phone:</b> {orderInformation.phone}
                </div>
                <div>
                  <b>Order Created On:</b>{" "}
                  {getDateTimeFromUTC(orderInformation.created)}
                </div>
              </div>
            </div>
            <div className="w-2/5 pl-2 overflow-hidden overflow-y-auto">
              <div className="w-full text-center text-2xl font-sans mb-4">
                {`Order Status - ${orderInformation.status}`}
              </div>
              {orderInformation.productType === "Digital" ? (
                <div className="text-center text-gray-400">
                  No option available to change status of a <b>Digital Order</b>
                  .
                </div>
              ) : (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-4">
                  {ORDER_STATUS_VALUES.map((status, index) => (
                    <button
                      key={index}
                      value={status}
                      onClick={async () => {
                        if (
                          await handleOrderStatusChange(
                            status,
                            orderInformation,
                          )
                        ) {
                          setToastMessage(
                            `Status changed from ${orderInformation.status} to ${status}`,
                          );
                          setShowToast(true);
                          setTimeout(() => {
                            setShowToast(false);
                          }, 2000);
                          setOrderInformation((prevState) => ({
                            ...prevState,
                            status: status,
                          }));
                        }
                      }}
                      className={`${orderInformation.status === status
                        ? "bg-blue-300"
                        : "bg-gray-200"
                        } py-2 text-black-500 text-sm lg:text-base text-center overflow-hidden min-w-fit font-mono active:scale-[1.03] hover:font-semibold rounded-md shadow-md hover:bg-blue-400 cursor-pointer hover:text-white`}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              )}
              <div className="mx-5 p-3 mt-5">
                {pdfBase64 ? (
                  <>
                    <img
                      className="shadow-lg w-full"
                      src={pdfImageBase64}
                      alt="PDF display"
                    />
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <button
                        className="block text-sm lg:text-base bg-blue-500 mt-5 rounded-2xl active:scale-[1.02] text-white py-3 w-full hover:bg-blue-600 font-bold font-sans mx-auto cursor-pointer"
                        onClick={() => {
                          const link = document.createElement("a");
                          link.href = `data:application/pdf;base64,${pdfBase64}`;
                          link.download = `${orderInformation.orderNumber} - Final PDF.pdf`;
                          link.click();
                        }}
                      >
                        &darr; PDF
                      </button>
                      <button
                        className="block text-sm lg:text-base bg-blue-500 mt-5 rounded-2xl active:scale-[1.02] text-white py-3 w-full hover:bg-blue-600 font-bold font-sans mx-auto cursor-pointer"
                        onClick={() => downloadSlip()}
                      >
                        &darr; Slip
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="loader mt-8 mx-auto"></div>
                    <div className="w-full text-center mt-6">
                      PDF is loading...
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderStatusModel;
